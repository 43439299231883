import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [pageTitle] = get(this, 'props.data.allContentfulTitleCard.edges')
    const node = pageTitle.node

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title="Arithma Accounting Solutions" />
          <Hero data={node} />
          <Img
            alt={node.title}
            fluid={node.logo.fluid}
            style={{
              margin: '0 auto',
              marginTop: 20,
              maxHeight: 300,
              maxWidth: 300,
            }}
          />
        </div>

        <div style={{ padding: '10px', margin: '0 auto', textAlign: 'center' }}>
          <p>{node.shortDescription || '<short description>'}</p>
          <p>{node.contactInfo || '<contact info>'}</p>
          <p style={{ marginTop: 30 }}>
            <span
              style={{
                padding: 3,
                backgroundColor: '#f6b21b',
                borderRadius: 3,
              }}
            >
              {node.underConstructionMessage || 'UNDER CONSTRUCTION'}
            </span>
          </p>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTitleCard {
      edges {
        node {
          contactInfo
          underConstructionMessage
          logo {
            fluid(resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          shortDescription
          image {
            fluid(resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
